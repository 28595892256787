<template>
  <div>
    <div class="page-content">
      <div class="page-sub-title flex flex-center">
        <span class="flex-1">筛选查询</span>
      </div>
      <div>
        <div class="search-item">
          <span class="label">地区名称</span>
          <el-input v-model="searchParams.areaName" size="small" clearable style="width: 240px" placeholder="请输入地区名称"></el-input>
        </div>
        <div class="search-item">
          <span class="label">ID</span>
          <el-input v-model="searchParams.code" size="small" clearable style="width: 240px" placeholder="请输入ID"></el-input>
        </div>
        <div class="search-item">
          <el-button type="primary" size="small" @click="getList">查询</el-button>
          <el-button class="border-btn" plain size="small" @click="reset">重置</el-button>
        </div>
      </div>
      <div class="page-sub-title flex flex-center">
        <span class="flex-1">地区码表</span>
      </div>
      <el-table
        v-loading="loading"
        :data="list"
        ref="theTable"
        style="width: 100%"
        border
        row-key="code"
        :expand-row-keys="isOpen"
        header-row-class-name="table-header"
        lazy
        :row-style="rowStyle"
        :tree-props="{children: 'childAreaList', hasChildren: 'hasChildren'}"
      >
        <el-table-column
          align="left"
          prop="name"
          label="地区名称"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="code"
          label="地区ID"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="level"
          label="地区属性"
        >
          <template slot-scope="scope">
            <span>{{scope.row.level === "PROVINCE"?'省':(scope.row.level === "CITY"?'市':'区')}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { 
  post_allAreaList
} from "@/api/base";
export default {
  data() {
    return {
      loading: true,
      list: [],
      searchParams: {
        areaName: '',
        code: ''
      },
      isOpen: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList(type) {
      this.loading = true
      this.isOpen = []
      const res = await post_allAreaList({
        ...this.searchParams
      })
      if(type !== 'reload') {
        res.map(o => {
          if(o.isOpen) {
            this.isOpen.push(o.code)
          }
          o.childAreaList.map(p => {
            if(p.isOpen) {
              this.isOpen.push(p.code)
            }
            p.childAreaList.map(q => {
              if(q.isOpen) { 
                this.isOpen.push(q.code)
              }
            })
          })
        })
      }else {
        this.forArr(res, false)
      }
      this.list = res ? res : []
      this.loading = false
      console.log(this.isOpen,'this.isOpen')
    },
    forArr(arr, isExpand) {
      arr.forEach(i => {
        this.$refs.theTable.toggleRowExpansion(i, isExpand)
        if (i.children) {
          this.forArr(i.children, isExpand)
        }
      })
    },
    rowStyle(row) {
      if(row.row.isHighLight) {
        return {
          'background-color': 'rgb(234, 244, 255)'
        }
      }
      if(row.row.level === 'PROVINCE') {
        return {
          'background-color': '#f9f9f9'
        }
      }
    },
    reset() {
      this.isOpen = []
      this.searchParams = {
        areaName: '',
        code: ''
      }
      this.getList('reload')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>